@import '../../../global/styles/helpers';

.dial {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw;
    height: 80vw;
    max-width: 60vh;
    max-height: 60vh;
    background-color: transparent;
    border: 4px solid #fff;
    border-radius: 50%;

    @include media('landscape', 'timer-height-md') {
      width: 80vh;
      height: 80vh;
      max-width: 90vw;
      max-height: 90vw;
    }

    @include media('timer-height-xs') {
      width: 85vh;
      height: 85vh;
      max-width: 85vh;
      max-height: 85vh;
    }
  }

  &__time {
    font-variant-numeric: tabular-nums;
    color: #fff;

    @include fluid-type(font-size, 200px, 600px, 40px, 70px);

    @include media('landscape', 'timer-height-md') {
      font-size: 14vh;
    }

    @include media('landscape', 'timer-height-xs') {
      font-size: 18vh;
    }

    @at-root .is-paused & {
      animation: blink 1000ms ease-in infinite;
    }
  }

  &__start {
    padding: em(8);
    background-color: transparent;
    color: #fff;
    font-size: $body-size;
    border: none;

    @include media('timer-height-md') {
      display: none;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: rgba(#000, 0.1);
    border: none;
    border-radius: 50%;
    animation: fadeInUp 300ms ease-in;

    &:hover {
      background-color: rgba(#000, 0.2);
    }

    span {
      display: inline-block;
      width: 4px;
      height: 4px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 16%;

    @include media('timer-height-md') {
      right: em(16);
      width: auto;
      height: 100%;
      align-items: center;
    }
  }
}
