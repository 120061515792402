.list {
  display: flex;
  flex-direction: column;
  margin-bottom: em(12);

  &__heading {
    font-size: $body2-size;
    font-weight: $body2-weight;
    color: $text-hint;
    margin-left: em(12);
    margin-bottom: em(8);
  }

  &__item {
    position: relative;
    display: flex;
    width: 100%;
    font-size: $body-size;
    line-height: $body-line-height;
    color: $text;
    background-color: #fff;
    padding: em(12) em(16);
    margin-bottom: em(4);
    border: none;
    box-shadow: 0 1px 2px rgba(#000, 0.15);

    &:hover {
      color: inherit;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__icon {
    display: flex;
    margin-right: em(16);
  }

  &__actions {
    display: flex;
    align-items: center;
    font-size: $body2-size;
    color: $text-hint;

    button {
      width: em(24);
      height: em(24);
      font-size: $body-size;
      background-color: transparent;
      background-position: center;
      border: none;
    }
  }

  // Styles for nested lists
  &__child {
    @include z-depth(2);
    margin: em(-4) 0 em(8) em(16);
    border-bottom-left-radius: 4px;
    background-color: #fff;

    &-heading {
      padding: em(4) em(16);
      font-size: $caption-size;
      background-color: #f9f9f9;
      border-bottom: 1px solid $divider;
    }

    &-item {
      display: block;
      padding: em(12) em(16);
      font-size: em(15);
      color: $text;
      border-bottom: 1px solid $divider;

      &:hover {
        color: $text;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
