@import '../../global/styles/helpers';

.login {
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
  }

  &__content {
    padding: em(20);
    width: 100%;
    max-width: 380px;

    input:not(:focus):not(.is-danger) {
      border-color: $divider-alt;
    }

    .label {
      color: $text;
      font-weight: 600;
      font-size: 1em;
    }
  }

  &__wordmark {
    text-align: center;
    margin-bottom: em(24);

    img {
      width: em(112);
    }
  }

  &__google.button {
    position: relative;
    display: block;
    background-color: #4885ed;
    text-align: center;
    padding-top: em(16);
    padding-bottom: em(16);

    &:hover {
      background-color: darken(#4885ed, 5%);
    }
  }

  &__google-icon {
    position: absolute;
    top: calc(50% - 9px);
    left: 16px;
  }

  &__divider {
    margin: em(32) 0 em(20);
    background-color: $divider-alt;
    height: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: 'or';
      display: inline-block;
      padding: 0 em(8);
      font-size: em(12);
      color: $text-hint;
      background-color: $background-color;
      text-transform: uppercase;
    }
  }

  &__submit-field {
    margin-top: em(20);

    button {
      margin-right: em(12);
    }
  }
}
