@import '../../../global/styles/helpers';

.toast {
  @include z-depth(3);
  background: $text;
  color: #fff;
  padding: em(16);
  border-radius: 4px;
  flex-grow: 1;

  @include media('>tablet') {
    flex-grow: 0;
  }

  &-entering {
    animation: fadeInUp 150ms ease-out;
  }

  &-exiting {
    animation: fadeOutDown 150ms ease-in;
  }

  &__container {
    position: fixed;
    z-index: 1100;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: em(16);
    overflow: hidden;

    @include media('>tablet') {
      justify-content: center;
    }
  }
}
