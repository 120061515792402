@import '../../../global/styles/helpers';

.sheet {
  &__overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.35);

    &.is-entering {
      animation: fadeIn 200ms ease-in-out;
    }

    &.is-exiting {
      animation: fadeOut 200ms ease-in-out;
    }
  }

  &__component {
    flex-grow: 1;
    background-color: #fff;

    &.is-entering {
      animation: fadeInUp 160ms ease-in-out;
    }

    &.is-exiting {
      animation: fadeOutDown 160ms ease-in-out;
    }
  }

  &__item {
    display: flex;
    padding: em(16);
    color: $text;
    cursor: pointer;
  }

  &__icon {
    display: inline-block;
    width: em(24);
    height: em(24);
    margin-right: em(32);
  }
}
