@import '../../global/styles/helpers';

.connect {
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
  }

  &__content {
    padding: em(20);
    width: 100%;
    max-width: 380px;
    text-align: center;
  }
}
