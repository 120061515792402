.is-loading {
  &:after {
    content: '';
    position: relative;
    display: block;
    height: 1em;
    width: 1em;
    border: 2px solid #fff;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    animation: spin 0.5s linear infinite;
  }

  @at-root .button#{&} {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    &:after {
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
    }
  }
}
