@import '../../../global/styles/helpers';

.menu {
  &__panel {
    @include z-depth(4);
    position: fixed;
    z-index: 950;
    top: 0;
    width: 85%;
    height: 100vh;
    max-width: 300px;
    background-color: #fff;
    overflow-y: auto;
    transform: translate3d(-100%, 0, 0);

    @at-root {
      .menu-enter-active & {
        transform: translate3d(0, 0, 0);
        animation: fadeInLeft 300ms ease-in-out;
      }

      .menu-enter-done & {
        transform: translate3d(0, 0, 0);
      }

      .menu-exit-active & {
        animation: fadeOutLeft 300ms ease-in-out;
      }
    }
  }

  &__profile {
    padding: em(20) em(16) em(12) em(16);
    margin-bottom: em(12);
    border-bottom: 1px solid $divider;
  }

  &__email {
    font-size: $body2-size;
    color: $body2-color;
  }

  &__item {
    display: flex;
    padding: em(12) em(16);
    align-items: center;
    color: #444;

    &:hover {
      color: #222;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: em(24);
    height: em(24);
    margin-right: em(24);
  }

  &__overlay {
    position: fixed;
    z-index: 949;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000, 0.35);
    opacity: 0;

    @at-root {
      .menu-enter-active & {
        opacity: 1;
        animation: fadeIn 300ms ease-in-out;
      }

      .menu-enter-done & {
        opacity: 1;
      }

      .menu-exit-active & {
        animation: fadeOut 300ms ease-in-out;
      }
    }
  }
}
