.button {
  display: inline-block;
  background-color: $primary;
  color: #fff;
  font-size: 1em;
  line-height: 1;
  padding: em(12) em(16);
  border: none;
  border-radius: em(4);

  &:hover {
    background-color: darken($primary, 5%);
    color: #fff;
  }

  &.is-small {
    font-size: 0.875em;
  }

  &.is-text {
    background-color: transparent;
    color: $info;

    &:hover {
      color: darken($info, 15%);
    }
  }
}
