.secondary-bar {
  background-color: #fff;
  border-bottom: 1px solid $divider-alt;
  margin-bottom: em(12);
  margin-top: em(-12);
  overflow-x: auto;
  padding: em(12) em(24);
  white-space: nowrap;

  &.is-small {
    font-size: em(14);
    padding-bottom: em(10);
    padding-top: em(10);
  }
}