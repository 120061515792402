$background-color: #eee;

$family-sans-serif: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  'Helvetica', 'Arial', sans-serif;
$family-monospace: monospace;

$primary: #00c7ad;

$task: #f34235;
$shortBreak: #2095f2;
$longBreak: #4bae4f;

$info: #3273dc;

$danger: #ff3860;

$text: #444;
$text-hint: rgba($text, 0.5);

$divider: #eee;
$divider-alt: #ddd;

$link: $info;

$placeholder: rgba($text, 0.15);

$body-family: $family-sans-serif;
$body-color: $text;
$body-size: 16px;
$body-line-height: 1.5;
$body-weight: 400;
$body-weight-bold: 700;

$body2-size: 14px / $body-size * 1em;
$body2-color: rgba(#000, 0.6);
$body2-weight: 400;

$caption-size: 12px / $body-size * 1em;
$caption-color: $text-hint;
$caption-weight: 400;
