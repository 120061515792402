@import '../../../global/styles/helpers';

.app-bar {
  @include z-depth(2);
  position: sticky;
  top: 0;
  z-index: 900;
  display: flex;
  align-items: center;
  height: em(56);
  padding: em(16) em(12);
  margin-bottom: em(12);
  background-color: $primary;

  &.is-transparent {
    background-color: transparent;
    box-shadow: none;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: em(32);
    height: em(32);
    margin-right: em(28);
    padding: 0;
    font-size: $body-size;
    background-color: transparent;
    border: none;

    a {
      line-height: 0;
    }
  }

  &__title {
    font-size: em(20);
    font-weight: 600;
    color: #fff;
  }
}
