@import '../../global/styles/helpers';

.tasks-page {
  .list__item {
    position: relative;
  }

  &__lists {
    padding: 0 em(4);
  }

  &__list {
    position: relative;
    display: inline-block;
    margin: em(12);
    color: transparent;
    font-weight: 600;

    &:hover {
      color: transparent;
    }

    &:before {
      content: attr(data-list);
      position: absolute;
      width: 100%;
      color: $text;
      text-align: center;
      font-weight: 400;
    }

    &.active {
      &:before {
        font-weight: 600;
      }
    }
  }
}
