@import '../../../global/styles/helpers';

.overtime {
  bottom: 8px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 100;

  &__popup {
    @include z-depth(2);
    background-color: $danger;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    padding: 6px 10px;
    animation: fadeInUp 150ms ease-out;
  }

  &__time {
    font-variant-numeric: tabular-nums;
  }
}