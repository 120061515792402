html {
  background-color: $background-color;
  font-size: $body-size;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
button,
input,
select,
textarea {
  font-family: $family-sans-serif;
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: $family-monospace;
}

body {
  font-size: 1rem;
  color: $text;
  font-weight: $body-weight;
  line-height: $body-line-height;
}

a {
  color: $link;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: darken($link, 10%);
  }

  strong {
    color: currentColor;
  }
}

img {
  height: auto;
  max-width: 100%;
}

input[type='checkbox'],
input[type='radio'] {
  vertical-align: baseline;
}

small {
  font-size: 0.875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  font-weight: $body-weight-bold;
}

table {
  td,
  th {
    text-align: left;
    vertical-align: top;
  }
}
