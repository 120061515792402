@import '../../global/styles/helpers';

.boards {
  &__icon {
    display: inline-block;
    background-size: cover;
    width: em(32);
    height: em(32);
    border-radius: 50%;
  }
}
