@import '../../../global/styles/helpers';

.modal {
  @include z-depth(8);
  background-color: #fff;
  margin-top: em(-20);
  padding: em(24) em(24) 0;
  width: em(280);
  border-radius: 8px;

  &__buttons {
    display: flex;
    margin: em(20) em(-24) 0;
    border-top: 1px solid $divider;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: $body-size;
    height: 48px;
    background: transparent;
    color: rgba($text, 0.5);
    border: none;
    border-left: 1px solid $divider;

    &:hover {
      color: rgba($text, 0.75);
    }

    &:first-child {
      border-left: none;
    }

    &:last-child {
      color: $link;
      font-weight: 500;

      &:hover {
        color: $link;
      }
    }
  }

  &__overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.35);
  }
}
