.settings {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(#000, 0.15);
  margin-bottom: em(12);

  &__item {
    align-items: center;
    border-bottom: 1px solid $divider;
    display: flex;
    min-height: em(60);
    padding: em(10) em(16);

    &:last-child {
      border-bottom: none;
    }
  }

  &__label {
    flex-grow: 1;
  }

  &__suffix {
    display: inline-block;
    margin-left: em(8);
  }

  &__hint {
    color: $text-hint;
    font-size: em(13);
    width: 90%;
  }
}
