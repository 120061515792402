input:not([type='checkbox']):not([type='radio']),
textarea,
select {
  font-size: $body-size;
  border: 1px solid $divider;
  color: $text;
  padding: em(12) em(16);
  border-radius: em(4);

  &:focus {
    border-color: $info;
    box-shadow: 0 0 0 0.125em rgba($info, 0.25);
    outline: none;
  }

  &.is-danger {
    border-color: $danger;

    &:focus {
      box-shadow: 0 0 0 0.125em rgba($danger, 0.25);
    }
  }
}

.select {
  position: relative;
  display: inline-block;

  select {
    -webkit-appearance: none;
    background-color: #fff;
    line-height: 1;
    padding-bottom: em(10);
    padding-right: em(36);
    padding-top: em(10);
  }

  &:after {
    content: '';
    top: calc(50% - 12px);
    right: em(8);
    position: absolute;
    width: em(24);
    height: em(24);
    background-image: url(../../global/assets/chevron-down.svg);
    pointer-events: none;
  }
}

.range {
  align-items: center;
  display: flex;
  height: em(40);
  padding-left: em(6);
  padding-right: em(6);

  &__thumb {
    background-color: $info;
    border-radius: 50%;
    height: em(12);
    margin-top: -7px;
    width: em(12);
  }

  &__track {
    background-color: rgba($info, .16);
    border-bottom: 7px solid #fff;
    border-top: 7px solid #fff;
    height: 16px;
    width: 100%;
  }
}

.field {
  margin-bottom: em(12);

  input:not([type='checkbox']):not([type='radio']),
  textarea,
  select,
  .select {
    width: 100%;
  }

  .help {
    margin-top: em(8);
    font-size: $caption-size;

    &.is-danger {
      color: $danger;
    }
  }
}

.label {
  display: inline-block;
  color: $caption-color;
  font-size: $caption-size;
  margin-bottom: em(8);
}
