@import '../../../global/styles/helpers';

.placeholder {
  display: inline-block;
  background-color: $placeholder;
  animation: pulse 1s ease-in infinite;

  &-line {
    border-radius: 2px;
    min-width: em(20);
  }

  &-icon {
    border-radius: 50%;
  }
}
