.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.25em;
  }

  p:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
