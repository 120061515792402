@import '../../../global/styles/helpers';

.clock {
  @each $mode,
    $color
      in (
        'default': $primary,
        'task': $task,
        'short-break': $shortBreak,
        'long-break': $longBreak
      )
  {
    &__container[data-type='#{$mode}'].is-active,
    [data-type='#{$mode}'] &__background {
      background-color: $color;
    }
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
  }

  &__content {
    height: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25%;
    padding: 0 em(16);
    text-align: center;

    @include media('landscape', 'timer-height-md') {
      display: block;
      margin: 34vh auto 0 auto;
      width: 64vh;
    }
  }

  &__eyebrow {
    font-weight: 600;
    color: #fff;
    opacity: 0.72;

    @include media('timer-height-md') {
      display: none;
    }
  }

  &__heading {
    font-size: em(20);
    font-weight: 600;
    color: #fff;

    @include media('timer-height-md') {
      font-size: em(16);

      @include media('landscape') {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @include media('timer-height-sm') {
      font-size: em(14);
    }

    @include media('timer-height-xs') {
      display: none;
    }
  }

  &__dial {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__background {
    position: fixed;
    z-index: -1;
    width: 250vh;
    height: 250vh;
    margin-top: -125vh;
    margin-left: -125vh;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 750ms ease-in-out;

    &.is-active {
      transform: scale(1);
    }

    @include media('landscape') {
      width: 250vw;
      height: 250vw;
      margin-top: -125vw;
      margin-left: -125vw;
    }
  }
}
