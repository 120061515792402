@import '../../../global/styles/helpers';

.mini-clock {
  bottom: 0;
  pointer-events: none;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 100;

  &__time {
    background-color: $task;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: em(14);
    font-variant-numeric: tabular-nums;
    padding: em(4) em(12);
  }
}