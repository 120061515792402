h1 {
  font-size: em(96);
  font-weight: 200;
  letter-spacing: -1.5;
  line-height: 1;
}

h2 {
  font-size: em(60);
  font-weight: 200;
  letter-spacing: -0.5;
  line-height: 1;
}

h3 {
  font-size: em(48);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.1;
}

h4 {
  font-size: em(34);
  font-weight: 400;
  letter-spacing: 0.25;
  line-height: 1.1;
}

h5 {
  font-size: em(24);
  font-weight: 400;
  letter-spacing: 0.25;
  line-height: 1.2;
}

h6 {
  font-size: em(20);
  font-weight: 600;
  letter-spacing: 0.15;
  line-height: 1.2;
}
